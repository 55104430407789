<template>
  <div class="login">
    <div class="breadcrumbs-wrapper">
      <SfBreadcrumbs class="breadcrumbs container" :breadcrumbs="breadcrumbs" />
    </div>
    <div v-if="story" class="container">
      <section
        v-for="(blok, index) in story.content.body"
        :key="index + 'blok'"
      >
        <component
          v-if="blok.component"
          :key="blok._uid"
          :blok="blok"
          :is="blok.component"
        />
      </section>
    </div>
  </div>
</template>

<script>
import {
  useFetch,
  ref,
  useContext,
  computed,
  useRoute,
} from "@nuxtjs/composition-api";
import { SfBreadcrumbs } from "@storefront-ui/vue";
import { getMetaInfo } from "~/helpers/getMetaInfo";

export default {
  name: "LoginPage",
  components: {
    SfBreadcrumbs,
  },
  layout: "footerwithups",
  setup() {
    const story = ref({ content: {} });
    const { app } = useContext();
    const route = useRoute();
    const breadcrumbs = ref([
      {
        link: app.localePath("/"),
        text: app.i18n.t("Home"),
      },
      {
        link: app.localePath("/login"),
        text: app.i18n.t("Login"),
      },
    ]);

    const baseUrl = app.$config.baseUrl;
    const locale = route.value.fullPath;

    const metatags = computed(() => story?.value?.content.metatags);

    useFetch(async () => {
      await app.$storyblok("login").then((res) => {
        story.value.content = res.story.content;
      });
    });

    return {
      story,
      breadcrumbs,
      metatags,
      baseUrl,
      locale,
    };
  },
  head() {
    return getMetaInfo(this.metatags, this.locale, this.baseUrl);
  },
};
</script>
